export default {
    menuItems : [
        { url: '/faculty', index: '1', i18nKey: 'faculty' },
        { url: '/research-groups', index: '2', i18nKey: 'research_groups' },
        { url: '/events', index: '3', i18nKey: 'events' },
        { url: '/blog', index: '4', i18nKey: 'blog' },
        { url: '/opportunities', index: '5', i18nKey: 'opportunities' },
        { url: '/about', index: '6', i18nKey: 'about_contact' }
    ]
};
