import { render, staticRenderFns } from "./MyPlayer.vue?vue&type=template&id=42b677fd&scoped=true"
import script from "./MyPlayer.vue?vue&type=script&lang=js"
export * from "./MyPlayer.vue?vue&type=script&lang=js"
import style0 from "./MyPlayer.vue?vue&type=style&index=0&id=42b677fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b677fd",
  null
  
)

export default component.exports