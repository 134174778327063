<template>
    <div class="width1200 research-projects">
        <h1 class="h1-title research-projects__title">{{getLangText(titleKey)}}</h1>
        <el-row :gutter="54">
            <el-col v-for="(project, index) in displayedProjects" :key="index" :span="8">
                <div class="project-card"  @click="navigateToProject(project)">
                    <img :src="project.image"  :alt="project.title">
                    <div class="project-card__overlay">
                        <h3 class="def">{{ project.title }}</h3>
                        <h4>{{ project.title }}</h4>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div class="research-projects__more" v-if="hasMoreProjects">
            <el-button
                    class="more-button"
                    @click="loadMore"
            >
                {{ getLangText(moreTextKey) }}
            </el-button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'ResearchProjects',
        props: {
            // 数据相关属性
            projects: {
                type: Array,
                required: true,
                default: () => []
            },
            projectsPerPage: {
                type: Number,
                default: 3
            },
            titleKey: {
                type: String,
                default: ''
            },
            moreTextKey: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapGetters(['getLangText']),
            displayedProjects() {
                return this.projects.slice(0, this.currentPage * this.projectsPerPage)
            },
            hasMoreProjects() {
                return this.displayedProjects.length < this.projects.length
            }
        },


        data() {
            return {
                currentPage: 1
            }
        },
        methods: {
            loadMore() {
                this.currentPage++
            },
            navigateToProject(project) {
                this.$router.push(project.route)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .research-projects{
        &__title{
            padding: 40px 0;
        }
        .project-card{
            position: relative; width: 100%; margin-bottom: 54px;
            &>img{width: 100%; height: auto}
            &__overlay {
                position: absolute; cursor: pointer;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .def{position: absolute; width:100%; background: rgba(0, 0, 0, 0.5); font-size: 28px;
                    text-align: center; bottom: 0; height: 80px; line-height: 80px; color: #fff;}
                h4{display: none;font-size: 28px;color: #fff;}
                &:hover{
                    display: flex;align-items: center;justify-content: center; background: rgba(0, 0, 0, 0.5);
                    transition: opacity 0.3s ease;
                    .def{display: none;}
                    h4{display: block;}

                }
            }
        }
        .research-projects__more{text-align: center;}
       .more-button {
            padding: 4px 24px;
            font-size: 18px;  border-radius: 25px; border: 1px solid #1B1B1B;
            &:hover{color:#fff; background-color: #1B1B1B}
        }
    }
</style>