import Vue from 'vue'
import {
    Button,Row, Col,Tag,Dialog,Image,
    Input,
    Table,
    TableColumn,
    Loading,
    MessageBox,
    Message,
    Notification
} from 'element-ui'

// 组件列表
const components = [
    Button,Row, Col,Tag,Dialog,Image,
    Input,
    Table,
    TableColumn
]

// 指令列表
const directives = [
    Loading
]

// 注册组件
components.forEach(component => {
    Vue.use(component)
})

// 注册指令
directives.forEach(directive => {
    Vue.use(directive.directive)
})

// 挂载原型方法
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message