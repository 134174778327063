<!-- ProfileModal.vue -->
<template>
    <el-dialog
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            width="450px"
            custom-class="profile-jump"
            @close="handleClose"
    >
        <!-- 使用 title 插槽替换默认header ,没有用-->
<!--        <div slot="title" style="display:none"></div>-->

        <!-- 主体内容 -->
        <div class="custom-modal">
            <!-- 关闭按钮 -->
            <div class="close-btn" @click="handleClose">
                <i class="el-icon-error"></i>
            </div>

            <div class="modal-content">
                <!-- 头像 -->
                <div class="avatar-wrapper">
                    <img v-lazy="data.image">
                </div>

                <!-- 名称 -->
                <h2 v-if="data.name" class="profile-name">
                    {{ data.name }} ({{ data.title }})
                </h2>

                <!-- 描述 -->
                <div class="profile-description">
                 {{ $t('facultyPage.searchLabel') }}：{{ data.researchInterests }}
                </div>
            </div>
        </div>

        <!-- 自定义Footer -->
        <template slot="footer">
                <div class="dialog-footer">
                    <a class="more" :href="data.homepage" target="_blank">{{ $t('facultyPage.more') }} </a>
                </div>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: 'ProfileModal',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit('update:visible', value);
                }
            }
        },
        methods: {
            handleClose() {
                this.$emit('update:visible', false);
                this.$emit('close');
            },
            handleAction(action) {
                if (typeof action.handler === 'function') {
                    action.handler();
                }
                this.dialogVisible = false;
            }
        }
    }
</script>
<style lang="scss">
    .profile-jump{
        .el-dialog__header{display: none;}
        .el-dialog__body{padding: 20px;}
        .more{
            color:#fff; padding: 6px; border-radius:14px; background-color:#1B1B1B;
        }
        .dialog-footer{text-align: center;}
    }

</style>

<style scoped lang="scss">
    .custom-modal {
        position: relative;
    }

    .close-btn {
        position: absolute;
        top: -72px;
        right: -72px;
        font-size: 36px;  color:#fff;
        cursor: pointer;
        transition: color 0.3s;
        &:hover{color:#fff;}
    }
    .modal-content {
        text-align: center;
        max-height: calc(90vh - 120px); /* 减去按钮区域的高度 */
        overflow-y: auto;
    }

    .avatar-wrapper {
        margin-bottom: 20px;
        &>img{width: 100%; max-width: 100%;}
    }

    .profile-name {
        margin: 16px 0;font-size: 20px; text-align: left;
        color: #303133;
        font-weight: bold
    }

    .profile-description {
        color: #606266; text-align: left;line-height: 1.6;margin-bottom: 24px;
    }

    .action-buttons {
        text-align: center; color:#000;
    }

    /* 自定义滚动条样式 */
    .modal-content::-webkit-scrollbar {
        width: 6px;
    }

    .modal-content::-webkit-scrollbar-thumb {
        background: #dcdfe6;
        border-radius: 3px;
    }

    .modal-content::-webkit-scrollbar-track {
        background: #f5f7fa;
    }
</style>