<template>
  <div class="contact-page">
      <div class="com-img">
          <img src="@/assets/img/ab1.png"/>
      </div>

      <div class="lab-page">
          <div class="content-wrapper">
              <!-- About Section -->
              <section class="about-section">
                  <h1 class="section-title">About</h1>
                  <div class="about-content">
                      <p class="description">
                          The Fudan Artificial Intelligence Lab (SAIL), founded in 1956 by Professor John McCarthy, continues to be a rich, intellectual and stimulating academic environment. Through multidisciplinary and multi-faculty collaborations, SAIL promotes new discoveries and explores new ways to enhance human-robot interactions through AI; all while developing the next generation of researchers. Our staff of dedicated professionals provide support to our academic and research groups, functioning as SAIL's backbone. SAIL staff support helps our researchers, visiting scholars and students to advance new discoveries and innovation. All these groups working together add to the depth and breadth of our cutting-edge research.
                      </p>
                      <el-button class="custom-btn">
                          Download our Brochure
                          <i class="el-icon-arrow-right el-icon--right"></i>
                      </el-button>
                  </div>
              </section>

              <!-- Director Letter Section -->
              <section class="letter-section">
                  <h2 class="letter-subtitle">Letter from our Director</h2>
                  <h3 class="letter-title">Welcome to the Fudan Artificial Intelligence Lab</h3>
                  <div class="letter-content">
                      <p>The Fudan Artificial Intelligence Lab (SAIL) was founded by Prof. John McCarthy, one of the founding fathers of the field of AI. While the discipline of AI has transformed in many fundamental ways since its inception in the 1950s, SAIL remains a proud leading intellectual hub for scientists and engineers, an education Mecca for students, and a center of excellence for cutting edge research work.</p>
                      <el-button class="custom-btn">
                          Read More
                          <i class="el-icon-arrow-right el-icon--right"></i>
                      </el-button>
                  </div>
              </section>
          </div>
      </div>
      <professors-list :professors="professorsList" />

      <div class="container">
          <!-- 标题部分 -->
          <h1 class="page-title">Contact</h1>

          <!-- 地址和地图部分 -->
          <div class="location-section">
              <div class="location-info">
                  <p class="location-text">We are located on the second floor of the Gates Computer Science Building.</p>

                  <div class="building-info">
                      <i class="el-icon-location"></i>
                      <a href="#" class="building-name">Gates Computer Science Building</a>
                  </div>

                  <p class="address">Shanghai Songhu Road 2005</p>

                  <div class="links">
                      <a href="#" class="link">Directions</a>
                      <a href="#" class="link">Campus Map</a>
                  </div>

                  <p class="note">If you are a prospective Ph.D. student, please apply via the CS admissions process.</p>
              </div>

              <div class="map-container">
                  <el-image :src="mapPic" fit="cover" class="map-image"></el-image>
              </div>
          </div>

          <!-- 联系信息部分 -->
          <div class="contact-section">
              <!-- 左侧通用查询 -->
              <div class="contact-group">
                  <h2 class="group-title">General Inquiries</h2>

                  <div class="contact-item">
                      <h3 class="contact-name">Prof. Chris Manning</h3>
                      <p class="contact-role">SAIL Director</p>
                      <a href="mailto:manning@stanford.edu" class="contact-email">manning@stanford.edu</a>
                  </div>

                  <div class="contact-item">
                      <h3 class="contact-name">Cindy Duong</h3>
                      <p class="contact-role">SAIL Website Questions</p>
                      <a href="mailto:duongc@stanford.edu" class="contact-email">duongc@stanford.edu</a>
                  </div>
              </div>

              <!-- 右侧工业伙伴计划 -->
              <div class="contact-group">
                  <h2 class="group-title">Industrial Affiliates Program</h2>

                  <div class="contact-item">
                      <h3 class="contact-name">Joseph Huang</h3>
                      <p class="contact-role">Executive Director</p>
                      <a href="mailto:Joseph.Huang@stanford.edu" class="contact-email">Joseph.Huang@stanford.edu</a>
                  </div>

                  <div class="contact-item">
                      <h3 class="contact-name">Cindy Duong</h3>
                      <p class="contact-role">SAIL Website Questions</p>
                      <a href="mailto:duongc@stanford.edu" class="contact-email">duongc@stanford.edu</a>
                  </div>
              </div>
          </div>
      </div>


  </div>
</template>


<script>
  export default {
    components: {
        ProfessorsList: () => import(/* webpackChunkName: "professor-list" */ '@/components/ProfessorsList.vue')
    },
   data() {
          return {
              mapPic : require('@/assets/img/ab8.png'),
              professorsList: [
                  {
                      avatar: require('@/assets/img/ab3.png'),
                      name: 'Maria David',
                      title: 'Professor',
                      researchTitle: 'Research project',
                      researchContent: 'Automated reasoning; satisfiability modulo theories (SMT); formal methods...'
                  },
                  {
                      avatar: require('@/assets/img/ab4.png'),
                      name: 'Maria David',
                      title: 'Professor',
                      researchTitle: 'Research project',
                      researchContent: 'Automated reasoning; satisfiability modulo theories (SMT); formal methods...'
                  }
                  // 可以添加更多教授信息...
              ]
          }
      }
  };
</script>


<style lang="scss" scoped>
    .contact-page {
        background-color: #fff;

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 20px;
        }

        .page-title {
            font-size: 32px; text-align: center;
            font-weight: 600;
            color: #000;
            margin-bottom: 40px;
        }

        // 地址和地图部分
        .location-section {
            display: flex;
            gap: 40px;
            margin-bottom: 60px;

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        .location-info {
            flex: 1;

            .location-text {
                font-size: 20px; font-weight: bold;
                line-height: 1.5;
                margin-bottom: 20px;
            }

            .building-info {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 12px;

                .building-name {
                    color: #0066cc;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: 500;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .address {
                font-size: 18px;
                color: #333;
                margin: 30px 0;
            }

            .links {
                margin-bottom: 20px;

                .link {
                    color: #0066cc;
                    text-decoration: underline;
                    margin-right: 16px;
                    font-size: 16px;

                    &:hover {
                        color: #004c99;
                    }
                }
            }

            .note {
                font-size: 18px;
                line-height: 1.5;
                color: #333;
            }
        }

        .map-container {
            flex: 1;
            min-height: 300px;
            background-color: #f5f5f5;
            border-radius: 8px;
            overflow: hidden;

            .map-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        // 联系信息部分
        .contact-section {
            display: flex;
            gap: 60px;
            padding-top: 40px;
            background-color: #f5f5f5;
            padding: 40px;
            border-radius: 8px;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 40px;
            }
        }

        .contact-group {
            flex: 1;

            .group-title {
                font-size: 24px;
                font-weight: 600;
                color: #000;
                margin-bottom: 30px;
            }
        }

        .contact-item {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            .contact-name {
                font-size: 18px;
                font-weight: 600;
                color: #000;
                margin-bottom: 8px;
            }

            .contact-role {
                font-size: 16px;
                color: #666;
                margin-bottom: 8px;
            }

            .contact-email {
                color: #0066cc;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .lab-page {
        width: 100%;
        min-height: 1588px;
        background: url('/img/ab2.png') no-repeat center center;
        background-size: cover;
        padding: 40px 0;

        .content-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 20px;
        }

        // About Section
        .about-section {
            margin-bottom: 80px;

            .section-title {
                font-size: 52px;
                font-weight: 600;
                margin: 60px 0;
                text-align: center;
                color: #333;
            }

            .about-content {
                max-width: 600px;

                .description {
                    font-size: 24px;
                    line-height: 1.6em;
                    color: #333;
                    margin-bottom: 30px;
                }
            }
        }

        // Letter Section
        .letter-section {
            text-align: center;

            .letter-subtitle {
                font-size: 24px;
                color: #666;
                margin: 180px 0 40px;
                font-weight: 500;
            }

            .letter-title {
                font-size: 32px;
                color: #333;
                margin: 0 0 30px;
                font-weight: 600;
            }

            .letter-content {
                max-width: 800px;
                margin: 0 auto;

                p {
                    font-size: 24px;
                    line-height: 1.6em;
                    color: #666;
                    margin-bottom: 70px;
                }
            }
        }

        // 通用按钮样式
        .custom-btn {
            background-color: #000;
            color: #fff;
            border: none;
            padding: 12px 24px;
            border-radius: 25px;
            font-weight: 500;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    // 响应式适配
    @media screen and (max-width: 768px) {
        .lab-page {
            .about-section {
                .about-content {
                    max-width: 100%;
                }
            }

            .letter-section {
                .letter-content {
                    padding: 0 20px;
                }
            }
        }
        .contact-page {
            .container {
                padding: 0 15px;
            }

            .page-title {
                font-size: 28px;
                margin-bottom: 30px;
            }

            .contact-section {
                padding: 30px;
            }
        }
    }

    @media (max-width: 480px) {
        .contact-page {
            .container {
                padding: 0 10px;
            }

            .page-title {
                font-size: 24px;
                margin-bottom: 20px;
            }

            .location-text {
                font-size: 16px;
            }

            .contact-section {
                padding: 20px;
            }

            .group-title {
                font-size: 20px;
            }

            .contact-item {
                .contact-name {
                    font-size: 16px;
                }

                .contact-role,
                .contact-email {
                    font-size: 14px;
                }
            }
        }
    }
</style>