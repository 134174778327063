<!-- FacultySection.vue -->
<template>
    <div class="faculty-container">
        <div class="faculty-content">
            <div class="faculty-header">
                <h1 class="faculty-title">{{ $t('facultyPage.title') }}</h1>
                <h2 v-show="$i18n.locale === 'en'"  class="faculty-subtitle"> {{ $t('facultyPage.subTitle') }}</h2>
            </div>

            <div class="faculty-grid">
                <div v-for="member in teachers" :key="member.id" class="faculty-card" @click="openModal(member)">
                    <div class="faculty-image-container">
                        <img v-lazy="member.image" :alt="member.name" class="faculty-image">
                    </div>
                    <h3 class="faculty-name"> {{ member.name }} </h3>
                    <!-- <h4>{{ $t('facultyPage.titleLabel')}}  {{ member.title }}</h4>
                    <div class="faculty-specialization">
                           <span>{{ $t('facultyPage.searchLabel')}}  {{ member.researchInterests }}</span>
                    </div> -->
                </div>
            </div>
        </div>

        <ProfileModal
                :visible.sync="modalVisible"
                :data="profileData"
                @close="handleClose"
        />
    </div>
</template>

<script>
    export default {
        name: 'FacultySection',
        components: {
            ProfileModal: () => import(/* webpackChunkName: "profile-modal" */ '@/components/ProfileModal.vue'),
        },
        computed: {
            teachers() {
                return this.$t('facultyPage.teachers')
            }
        },
        data() {
            return {
                modalVisible: false,
                profileData: {}
            }
        },
        methods:{
            handleClose() {
                this.modalVisible = false
                // 可以在这里添加关闭弹窗时的其他逻辑
                console.log('Modal closed')
            },
            openModal(member){
                this.profileData = member
                this.modalVisible = true;
            }
        }
    }
</script>

<style scoped lang="scss">
    .faculty-container {
        width: 100%;
        padding: 60px 20px;
        background-color: #ffffff;
    }

    .faculty-content {
        max-width: 1200px;
        margin: 0 auto;
    }

    .faculty-header {
        text-align: center;
        margin-bottom: 60px;
    }

    .faculty-title {
        font-size: 40px;
        font-weight: 700;
        margin: 0;
        line-height: 1.2;
    }

    .faculty-subtitle {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 1.2;
    }

    .faculty-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }

    .faculty-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transition:all .3s ease;
        &:hover {
           cursor: pointer;
           transform: scale(1.1);          
         }
    }

    .faculty-image-container {
        width: 100%; position: relative;
        margin-bottom: 16px;
        background-color: #f5f5f5;
        overflow: hidden;
    }

    .faculty-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .faculty-name {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 8px 0;
        color: #000000;
    }

    .faculty-specialization {
        font-size: 14px;
        line-height: 1.4;
        color: #333333;
    }

    .specialization-link {
        color: #0066cc;
        text-decoration: underline;
        transition: color 0.3s ease;
    }

    .specialization-link:hover {
        color: #004499;
    }

    /* Responsive Design */
    @media screen and (max-width: 1024px) {
        .faculty-grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .faculty-title {
            font-size: 36px;
        }

        .faculty-subtitle {
            font-size: 32px;
        }
    }

    @media screen and (max-width: 768px) {
        .faculty-grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .faculty-title {
            font-size: 32px;
        }

        .faculty-subtitle {
            font-size: 28px;
        }
    }

    @media screen and (max-width: 480px) {
        .faculty-grid {
            grid-template-columns: 1fr;
        }

        .faculty-container {
            padding: 40px 15px;
        }

        .faculty-title {
            font-size: 28px;
        }

        .faculty-subtitle {
            font-size: 24px;
        }

        .faculty-name {
            font-size: 18px;
        }
    }
</style>