export default {
    zh:'中文',
    en:'英文',
    faculty:'教师',
    research_groups:'课题组',
    events:'活动',
    blog:'博客',
    opportunities:'机会',
    about_contact:'关于 + 联系方式',
    desp:'可信人工智能研究院',
    keyWord:'关键字',
    internal:'内部',
    video_text:`TEAI：开创具身智能，共创更智能、更安全的未来`,
    research: {
        title: '研究项目',
        moreText: '更多项目'
    },
    groupTitle: '研究团体',
    months: {
        DEC: '十二月',
        // ... other months
    },
    new: '新',
    newsMore:'更多新闻',
    footer: {
        aboutUs: '关于我们',
        qrCode: '二维码'
    },
    facultyPage: {
        title: '师资队伍',
        subTitle: '（包括双聘人员）',
        more: '查看更多',
        nameLabel: '姓名：',
        titleLabel: 'Title：',
        searchLabel: '研究方向',
        homeLabel: '个人主页：',
        teachers: [
            {
              id: 1,
              name: '姜育刚',
              title: '教授，博士生导师',
              researchInterests: '计算机视觉，机器（深度）学习，视觉与语言，可信人工智能',
              image: require('@/assets/img/Yu-GangJiang.jpg'),
              homepage: 'https://fvl.fudan.edu.cn/'
            },
            {
                id: 2,
                name: '黄萱菁',
                title: '教授，博士生导师',
                researchInterests: '自然语言处理，信息检索，大语言模型',
                image: require('@/assets/img/XuanjingHuang.jpg'),
                homepage: 'https://xuanjing-huang.github.io/chinese/'
            },
            {
                id: 3,
                name: '张奇',
                title: '教授，博士生导师',
                researchInterests: '自然语言理解，大语言模型',
                image: require('@/assets/img/QiZhang.jpg'),
                homepage: 'http://qizhang.info/index_cn.html'
            },
            {
                id: 4,
                name: '邱锡鹏',
                title: '教授，博导',
                researchInterests: '自然语言处理，深度学习，大语言模型',
                image: require('@/assets/img/XipengQiu.jpg'),
                homepage: 'https://xpqiu.github.io/'
            },
            {
                id: 5,
                name: '吴祖煊',
                title: '副教授，博导',
                researchInterests: '计算机视觉，深度学习',
                image: require('@/assets/img/ZuxuanWu.jpg'),
                homepage: 'https://zxwu.azurewebsites.net/'
            },
            {
                id: 6,
                name: '费越',
                title: '高级研究员',
                researchInterests: '人工智能，计算机视觉，人机交互',
                image: require('@/assets/img/YueFei.jpg'),
                homepage: 'https://yw.fudan.edu.cn/3e/49/c44571a671305/page.htm'
            },
        
            {
                id: 7,
                name: '付彦伟',
                title: '教授、博士生导师',
                researchInterests: '基于大模型的迁移学习，如小样本/上下文学习，具身智能，脑信号神经解码',
                image: require('@/assets/img/YanweiFu.jpg'),
                homepage: 'https://yanweifu.github.io/'
            },
            {
                id: 8,
                name: '曹艺馨',
                title: '青年研究员、博士生导师',
                researchInterests: '自然语言处理，知识工程，多模态融合',
                image: require('@/assets/img/YixinCao.jpg'),
                homepage: 'https://taominer.github.io/'
            },
            {
                id: 9,
                name: '马兴军',
                title: '青年研究员、博士生导师',
                researchInterests: '可信人工智能，机器学习',
                image: require('@/assets/img/XingjunMa.jpg'),
                homepage: 'http://xingjunma.com/'
            },
            {
                id: 10,
                name: '桂韬',
                title: '副研究员',
                researchInterests: '自然语言处理，大语言模型，类人对齐，智能体交互',
                image: require('@/assets/img/TaoGui.jpg'),
                homepage: 'https://guitaowufeng.github.io/'
            },
            {
                id: 11,
                name: '陈智能',
                title: '青年研究员、博士生导师',
                researchInterests: '计算机视觉，AI4S，AIGC',
                image: require('@/assets/img/ZhinengChen.jpg'),
                homepage: 'https://zhinchenfd.github.io/'
            }  
        ]
    },

};