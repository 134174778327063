<!-- ProfessorsList.vue -->
<template>
    <div class="professors-list">
        <div class="container">
            <!-- 标题区域 -->
            <h1 class="main-title" v-if="title">{{ title }}</h1>

            <!-- 教授卡片列表 -->
            <div class="cards-container">
                <el-card
                        v-for="(professor, index) in professors"
                        :key="index"
                        class="professor-card"
                        shadow="hover"
                >
                    <div class="card-container">
                        <!-- 左侧头像区域 -->
                        <div class="avatar-section">
                            <el-image
                                    :src="professor.avatar"
                                    fit="cover"
                                    class="avatar-image"
                            >
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>

                        <!-- 右侧信息区域 -->
                        <div class="info-section">
                            <h2 class="professor-name">{{ professor.name }}</h2>
                            <p class="professor-title">{{ professor.title }}</p>

                            <div class="research-section">
                                <h3 class="research-title">{{ professor.researchTitle }}</h3>
                                <p class="research-content">{{ professor.researchContent }}</p>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    import {Card} from "element-ui";
    export default {
        name: 'ProfessorsList',
        components: {
            [Card.name]: Card
        },
        props: {
            title: {
                type: String,
                default: 'Professor'
            },
            professors: {
                type: Array,
                required: true,
                validator: function(array) {
                    return array.every(item =>
                        item.name &&
                        item.title &&
                        item.researchTitle &&
                        item.researchContent
                    );
                }
            }
        }
    }
</script>

<style scoped>
    .professors-list {
        width: 100%;
        padding: 20px 0;
    }
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .main-title {
        text-align: center;
        font-size: 32px;
        color: #303133;
        margin-bottom: 40px;
        font-weight: 600;
    }

    .cards-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .professor-card {
        width: 100%;
    }

    .card-container {
        display: flex;
        gap: 30px;
    }

    .avatar-section {
        flex: 0 0 auto;
        width: 200px;
    }

    .avatar-image {
        width: 100%;
        height: 200px;
        border-radius: 8px;
        object-fit: cover;
    }

    .info-section {
        flex: 1;
        min-width: 0;
    }

    .professor-name {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 8px 0;
        color: #303133;
    }

    .professor-title {
        font-size: 18px;
        color: #606266;
        margin: 0 0 20px 0;
    }

    .research-title {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 12px 0;
        color: #303133;
    }

    .research-content {
        font-size: 16px;
        line-height: 1.6;
        color: #606266;
        margin: 0;
        word-break: break-word;
    }

    /* 响应式布局 */
    @media screen and (max-width: 768px) {
        .container {
            padding: 0 15px;
        }

        .main-title {
            font-size: 28px;
            margin-bottom: 30px;
        }

        .card-container {
            flex-direction: column;
        }

        .avatar-section {
            width: 150px;
            margin: 0 auto;
        }

        .avatar-image {
            height: 150px;
        }

        .info-section {
            text-align: center;
        }
    }

    @media screen and (max-width: 480px) {
        .container {
            padding: 0 10px;
        }

        .main-title {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .cards-container {
            gap: 20px;
        }

        .professor-name {
            font-size: 20px;
        }

        .professor-title {
            font-size: 16px;
        }

        .research-title {
            font-size: 18px;
        }

        .research-content {
            font-size: 14px;
        }
    }
</style>