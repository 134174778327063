<!-- views/Home.vue -->
<template>
    <div class="home-content">
        <!-- 视频播放器容器 -->
        <full-screen-video
                :video-url="fullVideoUrl"
                @player-ready="onPlayerReady"
        />

        <div class="width1200">
            <div class="event_txt">
                The Institute of TEAI at Fudan University is dedicated to advancing interdisciplinary research on trustworthy embodied AI.
                By bringing together experts from multiple fields including computer vision, natural language processing, robotics, control systems, microelectronics, and
                technology ethics, the Institute fosters cross-disciplinary collaborative research to develop superintelligent agents capable of autonomous exploration,
                continuous evolution, and safe, controllable actions in the physical world. The Institute upholds the principle of "human-centered AI",
                advocates for "intelligence for good", and practice "social responsibility."
            </div>
        </div>
        <div class="video-show">
            <my-player :video-source="videoUrl" />
        </div>
        <research-projects :projects="projects"   titleKey="research.title"   moreTextKey="research.moreText" />
<!--        <news :newsItems="newsData"/>-->
        <div class="com-img" style="margin-top: 54px;">
            <img src="@/assets/img/home-img1.jpg"/>
        </div>

        <image-slider :slides="groupProjects"/>
        <faculty-welcome></faculty-welcome>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        name: 'Home',
        components: {
            FullScreenVideo: () => import(/* webpackChunkName: "full-video" */ '@/components/FullScreenVideo.vue'),
            // news: () => import(/* webpackChunkName: "news" */ '@/components/NewsGrid.vue'),
            ImageSlider: () => import(/* webpackChunkName: "image-slider" */ '@/components/ImageSlider.vue'),
            ResearchProjects: () => import(/* webpackChunkName: "projects" */ '@/components/ResearchProjects.vue'),
            FacultyWelcome: () => import(/* webpackChunkName: "welcome" */ '@/components/FacultyWelcome.vue'),
            MyPlayer: () => import(/* webpackChunkName: "my-player" */ '@/components/MyPlayer.vue')
        },
        computed: {
            ...mapState(['isHeaderFixed'])
        },
        data() {
            return {
                // 全屏视频
                lastScrollPosition: 0,
                fullVideoUrl: '/video/fud.mp4',
                scrollDirection: 'none', // 滚动方向：'up' 向上, 'down' 向下, 'none' 初始状态
                // 全屏视频

                videoUrl: '/video/test.mp4',
                newsData : [
                    {
                        id: 1,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news1.jpg'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: true
                    },
                    {
                        id: 2,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news2.jpg'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: true
                    },
                    {
                        id: 3,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news3.png'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: false
                    },
                    {
                        id: 4,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news4.png'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: false
                    },
                    {
                        id: 5,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news5.png'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: false
                    },
                    {
                        id: 6,
                        day: '06',
                        month: 'DEC',
                        year: '2024',
                        image: require('@/assets/img/news6.png'),
                        title: 'Follow Prof. Li\'s interview with CBS Mornings and on being named the "Godmother of AI"',
                        tags: ['# CBS', '# Godmother of AI'],
                        isNew: false
                    }
                ],
                projects: [
                    {
                        id: 1,
                        title: 'Holyoke Codes',
                        image: require('@/assets/img/proj1.png'),
                        route: '/projects/holyoke-codes'
                    },
                    {
                        id: 2,
                        title: 'From Grass to Gas',
                        image: require('@/assets/img/proj2.png'),
                        route: '/projects/grass-to-gas'
                    },
                    {
                        id: 3,
                        title: 'Art and AI',
                        image: require('@/assets/img/proj3.png'),
                        route: '/projects/art-and-ai'
                    },
                    {
                        id: 4,
                        title: 'AI Jumpstart',
                        image: require('@/assets/img/proj4.png'),
                        route: '/projects/ai-jumpstart'
                    },
                    {
                        id: 5,
                        title: 'Art and AI2',
                        image: require('@/assets/img/proj5.png'),
                        route: '/projects/art-and-ai'
                    },
                    {
                        id: 6,
                        title: 'AI Jumpstart2',
                        image: require('@/assets/img/proj6.png'),
                        route: '/projects/ai-jumpstart'
                    },
                ],
                groupProjects: [
                    {
                        id: 1,
                        title: 'Holyoke Codes',
                        image: require('@/assets/img/regrp1.jpg'),
                        route: '/projects/holyoke-codes'
                    },
                    {
                        id: 2,
                        title: 'From Grass to Gas',
                        image: require('@/assets/img/regrp2.jpg'),
                        route: '/projects/grass-to-gas'
                    },
                    {
                        id: 3,
                        title: 'Art and AI',
                        image: require('@/assets/img/regrp3.jpg'),
                        route: '/projects/art-and-ai'
                    },
                    {
                        id: 4,
                        title: 'AI Jumpstart',
                        image: require('@/assets/img/regrp4.jpg'),
                        route: '/projects/ai-jumpstart'
                    },
                    {
                        id: 5,
                        title: 'AI Jumpstart',
                        image: require('@/assets/img/regrp5.jpg'),
                        route: '/projects/ai-jumpstart'
                    }
                ]
            }
        },
        methods:{
            ...mapActions(['updateFixed']),
            onPlayerReady(player) {
                console.log('Player ready', player)
            },
            handleScroll() {
                const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop
                // 确定滚动方向
                if (currentScrollTop > this.lastScrollTop) {
                    this.scrollDirection = 'down'
                } else if (currentScrollTop < this.lastScrollTop) {
                    this.scrollDirection = 'up'
                }

                // 根据滚动方向和距离决定 header 定位
                if (this.scrollDirection === 'down') {
                    // 向下滚动时始终使用 absolute 定位
                    this.updateFixed(false)
                } else if (this.scrollDirection === 'up') {
                    // 向上滚动时，根据距离判断是否使用 fixed 定位
                    this.updateFixed(currentScrollTop > 40)
                }
                // 更新上一次滚动位置
                this.lastScrollTop = currentScrollTop
            }
        },
        mounted() {
            this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop
            window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
</script>

<style scoped lang="scss">
    .home-content {
        position: relative;width: 100%;
        .video-show {
            max-width: 1200px;
            margin: 0 auto;
            .video-container {
                max-width: 100%;
                margin: 20px auto;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                overflow: hidden;
            }
        }
        .event_txt{font-size: 30px; margin: 32px 0}
    }
</style>

