<template>
    <div class="blog">
        <div class="com-img">
            <img src="@/assets/img/blog1.jpg"/>
        </div>

        <blog-list :blogList="blogs"  titleKey="ALL POSTS"></blog-list>
    </div>
</template>

<script>
    export default {
        name: 'Blog',
        components: {
            BlogList: () => import(/* webpackChunkName: "blog-list" */ '@/components/BlogList.vue')
        },
        data() {
            return {
                blogs: [
                    {
                        title: 'Clover: Closed-Loop Verifiable Code Generation',
                        authors: ['Chuyue Sun', 'Ying Sheng'],
                        description: 'Clover paradigm checks consistencies among code, docstrings and annotations and enforces correctness in AI-generated code.',
                        image: require('@/assets/img/blog2.jpg')
                    },
                    {
                        title: 'Codebook Features: Sparse and Discrete Interpretability for Neural Networks',
                        authors: ['Alex Tamkin', 'Mohammad Taufeeque'],
                        description: 'A new interpretability method for training neural networks with sparse, discrete, and controllable hidden states.',
                        image: require('@/assets/img/blog3.jpg')
                    }
                ]
            }
        }
    }
</script>

<style scoped>

</style>