import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/data/language';

import translations from './modules/translations'
// 如果有其他模块也在这里导入
// import user from './modules/user'
// import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  // 根级别的状态
  state: {
    // 将i18n的消息存储到Vuex中
    messages: i18n.messages,
    isHeaderFixed:false
  },
  getters: {
    // 使用 i18n 的 $t 方法进行国际化
    getLangText: (state) => (key) => {
      return i18n.t(key);
    },
    // getFixed: state => state.isHeaderFixed
  },

  // 根级别的 mutations
  mutations: {
    // 全局 mutations
    updateFixed(state, newVal) {
      state.isHeaderFixed = newVal;
    }
  },

  // 根级别的 actions
  actions: {
    // 全局 actions
    updateFixed({ commit }, newVal) {
      commit('updateFixed', newVal);
    }
  },

  // 注册模块
  modules: {
     translations,
    // user,
    // settings
  }
})