<!-- views/VideoDemo.vue -->
<template>
    <div class="video-demo">
        <h2>视频优化示例</h2>
        <div class="video-container">
            <video-player
                    ref="videoPlayer"
                    class="video-player-box"
                    :options="playerOptions"
                    :playsinline="true"
            />
        </div>
    </div>
</template>

<script>
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player'

    export default {
        name: 'VideoDemo',
        components: {
            videoPlayer
        },
        data() {
            return {
                playerOptions: {
                    fluid: true,
                    responsive: true,
                    playbackRates: [0.5, 1, 1.5, 2],
                    sources: [{
                        type: "video/mp4",
                        // 使用示例视频
                        src: "https://www.w3schools.com/html/mov_bbb.mp4"
                    }],
                    controlBar: {
                        children: [
                            'playToggle',
                            'volumePanel',
                            'progressControl',
                            'currentTimeDisplay',
                            'timeDivider',
                            'durationDisplay',
                            'playbackRateMenuButton',
                            'fullscreenToggle'
                        ]
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .video-demo {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .video-container {
        max-width: 800px;
        margin: 20px auto;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
    }
</style>