import Vue from 'vue'
import './plugins/elementUI.js'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './data/language'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false

// 配置图片懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/img/var.png',
  loading: 'img/load.gif',
  attempt: 1,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
