// router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    // components: {
    //     default: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    //     fullVideo: () => import(/* webpackChunkName: "fullVideo" */ '../components/FullVideoPlay.vue')
    // },
    meta: { layout: 'HomeLayout', layoutClass: 'home-layout'}
  },
  {
    path: '/faculty',
    name: 'Faculty',
    component: () => import(/* webpackChunkName: "faculty" */ '../views/Faculty.vue'),
    meta: { layout: 'HomeLayout', layoutClass: 'faculty-layout'}
  },
  {
    path: '/research-groups',
    name: 'ResearchGroups',
    component: () => import(/* webpackChunkName: "/research-groups" */ '../views/ResearchGroups.vue'),
    meta: { layout: 'HomeLayout', layoutClass: 'research-groups-layout'}
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
    meta: { layout: 'HomeLayout', layoutClass: 'events-layout'}
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
    meta: { layout: 'HomeLayout', layoutClass: 'blog-layout'}
  },
  {
    path: '/opportunities',
    name: 'opportunities',
    component: () => import(/* webpackChunkName: "opportunities" */ '../views/Opportunities.vue'),
    meta: { layout: 'HomeLayout', layoutClass: 'opportunities'}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {layout: 'HomeLayout', layoutClass: 'about-layout'}
  },
  // {
  //   path: '/internal',
  //   name: 'Internal',
  //   component: () => import(/* webpackChunkName: "internal" */ '../views/Internal.vue'),
  //   meta: { layout: 'HomeLayout'}
  // },
  {
    path: '/image',
    name: 'ImageDemo',
    component: () => import(/* webpackChunkName: "image" */ '../views/ImageDemo.vue'),
    meta: {}
  },
  {
    path: '/video',
    name: 'VideoDemo',
    component: () => import(/* webpackChunkName: "video" */ '../views/VideoDemo.vue'),
    meta: {}
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的滚动位置（如返回上页），则返回保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则滚动到顶部
      return { x: 0, y: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  // 移除上一个页面的 body 样式
  if (from.meta) {
    let name = from.meta?.layoutClass ?? 'default-layout'
    document.body.classList.remove(name);
  }

  // 添加当前页面的 body 样式
  if (to.meta) {
    let name = to.meta?.layoutClass ?? 'default-layout'
    document.body.classList.add(name);
  }
  next();
});

export default router

