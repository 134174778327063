<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
  import HomeLayout from './layouts/HomeLayout.vue';
  import DefaultLayout from './layouts/DefaultLayout.vue';

  export default {
    computed: {
      layout() {
        const layoutName = this.$route.meta?.layout ?? 'DefaultLayout';
        return { HomeLayout, DefaultLayout }[layoutName];
      },
    },
    methods: {
    }
  };
</script>

<style lang="scss">
  html{font-size: 62.5%;-webkit-text-size-adjust:100%;margin:0;padding:0;}
  body{font-size: 16px;line-height:1.5;min-height:100vh;margin:0;padding:0;font-family: Avenir, Helvetica, Arial, sans-serif; color: #2c3e50;
    text-rendering:optimizeSpeed;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
  .com-img {
      &>img{width: 100%; max-width: 100%;}
  }
</style>
