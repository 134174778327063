import Vue from 'vue';
import VueI18n from 'vue-i18n'
import zh from './zh-cn'
import en from './en-us'

// 使用 vue-i18n 插件
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    // 定义语言包
    messages: {
        zh,
        en
    }
})

// 导出 i18n 实例
export default i18n;