<template>
    <div class="events">
        <div class="com-img">
            <img src="@/assets/img/eve1.jpg"/>
        </div>
        <div class="width1200">
            <h1 class="h1-title event-title">Events</h1>
            <div class="event_txt">
                Talks , reading groups , and events across the Stanford Al lab community , now held on re - motely on Zoom or other virtual meeting platforms .
                Note that some events are open to the public , some are open to only to Stanford people , and others are open to only individual groups
                ( audience is denoted by brackets ). If you are in the Stanford Al lab and would like to add an event , you can get edit access by contacting
                the respective representative from your research group .
            </div>
        </div>

        <div class="video-section">
            <my-player :video-source="videoUrl" />
        </div>

        <div class="width1200 content-wrapper">
            <!-- Header Title -->
            <div class="header">
                <h1>Fudan Computer Forum and CS Industry Affiliates Annual Meeting</h1>
            </div>

            <!-- Meeting Info -->
            <div class="section">
                <p>
                    The Fudan Computer Forum and CS Industry Affiliates Annual Meeting will be held in-person April 11-13, 2023. This Annual Meeting will be co-hosted by SAIL and the Fudan Computer Forum. The three-day event will present opportunities for our industrial partners to hear about latest developments in timely and critical areas of technology.
                </p>
            </div>

            <!-- Links Section -->
            <div class="links-section">
                <div class="link-item">
                    <div class="link-title">SAIL AI Workshop</div>
                </div>

                <div class="link-row">
                    <div class="link-title">Parking and Directions</div>

                </div>

                <div class="link-row">
                    <div class="link-title">Questions</div>

                </div>
            </div>

            <!-- Distinguished Speaker Series -->
            <div class="blue-header">
                Distinguished Speaker Series
            </div>
            <div class="section">
                <p>
                    The Fudan AI Lab Distinguished Speaker Series features talks given by the leaders of AI. The goal of these talks is to give an overview and foster discussion about the progress and challenges in the various areas of artificial intelligence.
                </p>
            </div>

            <!-- Student Activities -->
            <div class="black-header">
                SAIL Student Activities
            </div>
            <div class="section">
                <p>
                    Many postdocs, PhDs, masters, and undergraduate students involved in SAIL Research also spend time setting up events to build a sense of community, benefit fellow students, and help share the work of SAIL with the world. These activities are supported by SAIL leadership. You can see more details in the pages linked above.
                </p>
            </div>

            <!-- AI Salon -->
            <div class="black-header">
                AI Salon
            </div>
            <div class="section">
                <p>
                    The AI salon is a semi-monthly meeting of the Stanford AI lab to discuss questions that go beyond the day-to-day research in AI. Although now on Zoom, it has been held in the style of 18th-century French enlightenment-era salons, with no electronics, no powerpoint presentations, and an hourglass keeping time. The goal is to bring everyone together twice a month to contemplate the future of a fast-moving field. Past topics have included the ethics of autonomous driving, the role of theory in robotics, and intelligence augmentation. (Unless it is otherwise noted, AI Salon is only for current AI Lab members and their guests.)
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Events',
        components: {
             MyPlayer: () => import(/* webpackChunkName: "my-player" */ '@/components/MyPlayer.vue'),
        },
        data() {
            return {
                videoUrl: '/video/fud.mp4'
            }
        }
    }
</script>

<style scoped lang="scss">
    .event-title{padding: 32px 0;}
    .event_txt{font-size: 30px; margin-bottom: 50px;}
    .video-section ::v-deep{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        aspect-ratio: 16 / 9;
    }
    .content-wrapper {
        margin-top: 40px; margin-bottom: 40px;
    }

    .header {
        margin-bottom: 20px;
    }

    .header h1 {
        font-size: 32px;  background-color: #000;
        color: white;padding: 8px 12px;font-weight: bold;
    }

    .section {
        margin-bottom: 20px;
    }

    .section p {
        margin: 0;  font-size: 22px;
        line-height: 1.5;
        color: #333;
    }

    .links-section {
        margin-bottom: 20px;
    }

    .link-item, .link-row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        background-color: #f5f5f5;
        padding: 8px 12px;
    }

    .link-row {
        justify-content: space-between;
    }

    .link-title {
        font-weight: 500;
        color: #000;
    }

    .link-button {
        color: #0056b3;
        text-decoration: none;
        font-size: 14px;
    }

    .link-button:hover {
        text-decoration: underline;
    }

    .blue-header {
        background-color: #0056b3;
        color: white;font-size: 32px;
        padding: 8px 12px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .black-header {
        background-color: #000;font-size: 32px;
        color: white;
        padding: 8px 12px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    @media (max-width: 768px) {
        .page-container {
            padding: 15px;
        }

        .header h1 {
            font-size: 18px;
        }
    }

    @media (max-width: 480px) {
        .page-container {
            padding: 10px;
        }

        .link-row {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }
</style>