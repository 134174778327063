<template>
    <div class="news-slider-container">
        <h1 class="h1-title research-grop__title">{{$t('groupTitle')}}</h1>
        <div class="swiper-container" ref="swiperContainer">
            <div class="swiper-wrapper">
                <div
                        v-for="(slide, index) in showSlides"
                        :key="index"
                        class="swiper-slide"
                        :class="{'slide-active': isActiveSlide(index)}"
                >
                    <div class="slide-wrapper">
                        <div class="slide-content">
                            <div class="slide-image">
                                <img v-lazy="slide.image" :alt="slide.title">
                            </div>
                            <div class="slide-info">
                                <h3 class="slide-title">{{ slide.title }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
                class="custom-button prev"
                v-show="showPrevButton"
                @click="slidePrev">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div
                class="custom-button next"
                v-show="showNextButton"
                @click="slideNext"
        >
            <i class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>

<script>
    // script 部分保持不变
    import { Swiper } from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'

    export default {
        name: 'ImageSlider',
        props: {
            slides: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        computed: {
            showSlides() {
                if(Array.isArray(this.slides) && this.slides.length){
                    let arr = this.slides;
                    const [first] = arr;
                    const last = arr[arr.length - 1];
                    return [first, ...arr, last];
                }
                return [];
            },

        },
        data() {
            return {
                swiper: null,
                activeIndex: 0,
                showPrevButton: false,
                showNextButton: true
            }
        },
        mounted() {
            this.initSwiper()
        },
        beforeDestroy() {
            if (this.swiper) {
                this.swiper.destroy()
            }
        },
        methods: {
            initSwiper() {
                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    slidesPerView: 4,
                    spaceBetween: 30,
                    watchOverflow: true,
                    loop: false,
                    speed: 600,
                    on: {
                        slideChange: () => {
                            this.activeIndex = this.swiper.activeIndex
                            this.updateNavigationVisibility()
                        }
                    }
                })

                this.updateNavigationVisibility()
            },
            isActiveSlide(index) {
                return index === this.activeIndex + 1 || index === this.activeIndex + 2
            },
            updateNavigationVisibility() {
                this.showPrevButton = this.activeIndex > 0
                this.showNextButton = this.activeIndex < this.showSlides.length - 4
            },
            slideNext() {
                if (this.swiper && this.showNextButton) {
                    this.swiper.slideNext()
                }
            },
            slidePrev() {
                if (this.swiper && this.showPrevButton) {
                    this.swiper.slidePrev()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .news-slider-container {
        position: relative;
        /*width: 100vw;*/     /* 这个是不算纵向滚动条*/
        width: 100%;
        overflow-x: hidden;
        padding: 40px 0;
        background-color: #f5f5f5;
        .research-grop__title{margin-bottom: 30px;}
        .swiper-container {
            position: relative;
            transform: translateX(-100px);
            width: calc(100% + 200px);
            /*clip-path: inset(0 100px 0 100px);*/
            .swiper-slide {
                &:first-child,&:last-child  {
                    visibility: hidden;
                }
            }
        }
        .swiper-slide {
            transition: all 0.5s ease;
            position: relative;
            opacity: 0.5;
            padding: 10px;
            .slide-wrapper {
                background: white;
                border-radius: 12px;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
                overflow: hidden;
                transition: all 0.3s ease;
                height: 100%;
            }

            &::before {
                content: '';
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
                transition: all 0.3s ease;
                border-radius: 12px;
            }
            &.slide-active {
                opacity: 1;
                cursor: pointer;
                .slide-wrapper {
                    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
                }

                &::before {
                    opacity: 0;
                }

                &:hover {
                    .slide-content {
                        transform: scale(0.95);
                    }
                    .slide-wrapper {
                        box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }

        .slide-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            transition: transform 0.3s ease;
        }

        .slide-image {
            width: 100%;
            /*height: 200px;*/
            flex-shrink: 0;
            img {
                width: 100%;
                /*height: 100%;*/
                /*object-fit: cover;*/
            }
        }

        .slide-info {
            padding: 20px;
            background: white;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            z-index: 2;
        }

        .slide-title {
            font-size: 18px; text-align: center;
            margin: 0;
            line-height: 1.4;
            color: #333;
            // 文字超出显示省略号
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .custom-button {
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 10;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            &.prev {
                left: 30px;
            }
            &.next {
                right: 45px;
            }
            &:hover {
                background: white;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            }
            i {
                font-size: 20px;
                color: #333;
            }
        }
    }
</style>