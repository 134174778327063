<template>
    <div class="join-us">
        <div class="com-img">
            <img src="@/assets/img/opp1.png"/>
        </div>

        <div class="width1200">
            <h1 class="h1-title event-title">OPPORTUNITIES FOR STUDENTS</h1>
        </div>
        <div class="video-section">
            <my-player :video-source="videoUrl" />
        </div>
        <div class="width1200 event_txt">
            Through innovative research, the Institute strives to push the frontiers of Embodied AI technology, developing human-like,
            human-aware, and human-centered embodied systems, while shaping the future of human-machine collaboration and the intelligent society.
        </div>
        <info-section
                :description="description"
                :linksTitle="linksTitle"
                :links="links"
        ></info-section>
        <content-display :contentSections="sections"></content-display>
    </div>
</template>

<script>
   // import  MyPlayer from  '@/components/VideoPlayer.vue'
    export default {
        name: 'Opportunities',
        components: {
            MyPlayer: () => import(/* webpackChunkName: "my-player" */ '@/components/MyPlayer.vue'),
            InfoSection: () => import(/* webpackChunkName: "info-section" */ '@/components/InfoSection.vue'),
            ContentDisplay: () => import(/* webpackChunkName: "content-display" */ '@/components/ContentDisplay.vue')
        },
        data() {
            return {
                videoUrl: '/video/fud.mp4',
                description: 'The Institute is unique among Fudan schools: Although we do not award degrees, we offer unparalleled opportunities for undergraduates and graduate students. Fudan Radcliffe Institute provides learning and research experiences that are difficult to find in a traditional classroom setting. Radcliffe students work directly with our fellows and faculty, providing unique opportunities for mentorship, and they work across departmental boundaries, connecting with peers across the University. Harvard Radcliffe Institute enriches the Harvard student experience by fostering interdisciplinary, engaged scholarship focused on the most pressing issues of our time.',
                linksTitle: 'Quick Links',
                links: [
                    {
                        text: 'Join the Emerging Leaders Program',
                        url: '#',
                        underlined: false
                    },
                    {
                        text: 'Fund Your Research or Project',
                        url: '#',
                        underlined: true
                    },
                    {
                        text: 'Conduct Research with Faculty & Fellows',
                        url: '#',
                        underlined: false
                    },
                    {
                        text: 'Get Leadership Experience & Training',
                        url: '#',
                        underlined: false
                    },
                    {
                        text: 'Find Student Employment at Radcliffe',
                        url: '#',
                        underlined: false
                    }
                ],
                sections: [
                    {
                        title: 'Join the Emerging Leaders Program',
                        subtitle: 'Find Student Employment at Radcliffe',
                        description: 'Harvard Radcliffe Institute\'s work spans all disciplines and professions. The Institute provides invaluable support to scholars and students pursuing pathbreaking research and creative projects, including Radcliffe fellows, participants in seminars and workshops, and those inspired by the Schlesinger Library\'s rich collections.',
                        image: require('@/assets/img/opp2.png'),
                        imagePosition: 'right',
                        titleAlign: 'left',
                        link: {
                            text: 'More on Employment at Radcliffe',
                            url: '#'
                        }
                    },
                    {
                        title: 'Fund Your Research or Project',
                        subtitle: 'Radcliffe Engaged Student Grant Program',
                        description: 'The Radcliffe Engaged Student Grant Program provides $1,500 stipends per project to support the research, creative, and service work of Harvard undergraduate and graduate students on topics related to the Radcliffe Engaged focus areas.',
                        image: require('@/assets/img/opp3.png'),
                        imagePosition: 'left',
                        titleAlign: 'right',
                        link: {
                            text: 'More on the Radcliffe Engaged Student Grant Program',
                            url: '#'
                        }
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-section ::v-deep{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        aspect-ratio: 16 / 9;
    }
    .event_txt{font-size: 24px; margin-bottom: 50px; margin-top: 50px;font-weight: bold;}
    .event-title{margin-top: 70px; margin-bottom: 70px;}

</style>