<!-- https://tongyi.aliyun.com/qianwen/?sessionId=5b3a4df611e841a9bac5bb89e1c2ca89 -->
<template>
    <div class="home-page">
        <!-- 固定导航 -->
        <header id="header"  :class="{ 'header-fixed': isHeaderFixed }">
            <section class="flex-elem head-con">
                <div class="left_log"   @click="goHome()">
                    <img v-if="isHeaderFixed" :src="require('@/assets/img/logo2.png')" alt="log"/>
                    <img v-else :src="require('@/assets/img/logo.png')" alt="log"/>
                </div>

                <div class="flex-elem right_info">
                    <nav>
                        <div class="lang_nav">
                            <span v-for="(item,pos) in langList"  :key="pos"   @click="changeLanguage(item.i18nKey)"
                                  :class="{active: $i18n.locale === item.i18nKey}">
                            {{item.showText}}
                            </span>
                        </div>
                        <el-menu :default-active="activeIndex" class="el-menu-demo">
                            <el-menu-item
                                    v-for="item in menuItems"
                                    :key="item.index"
                                    :index="item.index">
                                <!-- 区分外链和内链 -->
                                <template v-if="isExternal(item.url)">
                                    <a :href="item.url" target="_blank">{{ item.showText }}</a>
                                </template>
                                <template v-else>
                                    <router-link :to="item.url" class="nav-link">{{ item.showText }}</router-link>
                                </template>
                            </el-menu-item>
                        </el-menu>
                    </nav>
                    <div class="search">
                        <el-input
                                :placeholder="`${$t('keyWord')}`"  size="small"
                                suffix-icon="el-icon-search"
                                v-model="keyWord">
                        </el-input>
                    </div>
                </div>
            </section>
        </header>
        <!-- 主内容区域 -->
        <main id="main" role="main"><slot /></main>
        <my-footer :footer-data="footerData" />

        <!-- 返回顶部按钮 -->
        <transition name="fade">
            <div v-show="false" id="backToTop" class="back-to-top" @click="scrollToTop">
                <el-button type="primary" circle class="back-to-top-btn">
                    <div class="button-content">
                        <i class="el-icon-top"></i>
                        <span class="button-text">TOP</span>
                    </div>
                </el-button>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'
    import {Menu, MenuItem} from "element-ui";

    export default {
        name: 'HomeLayout',
        components: {
            [Menu.name]: Menu,
            [MenuItem.name]: MenuItem,
            MyFooter: () => import(/* webpackChunkName: "my-foot" */ '@/components/MyFooter.vue')
        },
        watch: {
            $route: {
                immediate: true,
                handler(newRoute) {
                  if(newRoute.name === 'Home'){
                      this.updateFixed(false)
                  }else{
                      this.updateFixed(true)
                  }
                  this.updateActiveIndex(newRoute)
                }
            }
        },
        computed: {
            ...mapState(['isHeaderFixed']),
            // 从命名空间模块中获取状态
            ...mapState('translations', ['activeIndex']),
            // 通过mapGetters辅助函数从Vuex store中映射localizedArr到本地计算属性
            ...mapGetters('translations', ['localizedArr']),
            menuItems() {
                return this.localizedArr(this.$i18n,'menuItems')
            }
        },
        data() {
            return {
               keyWord:'',
               langList: [
                    {i18nKey: 'zh', showText: '中文'},
                    {i18nKey: 'en', showText: 'English'}
                ],
               backToTopButton: null,
               scrollHandler: null,
               footerData: {
                    logo: '/img/logo.png',
                    companyName: 'Company Name',
                    address: 'Shanghai Songhu Road 2005',
                    phone: '+86-21-31242255',
                    email: 'cs_school@fudan.edu.cn',
                    qrCode: '/img/qr-code.png',
                    copyright: '© Copyright 2024 XXX All rights reserved. 备案号:沪ICP备20241225号-1'
                }
            }
        },

        methods: {
           ...mapActions(['updateFixed']),
           ...mapActions('translations', ['updateActiveIndex']),
            isExternal(url) {
                return /^(https?:|mailto:|tel:)/.test(url)
            },
            goHome(){
               if(this.$route.name!=='Home'){
                   this.$router.push('/');
               }
            },
            changeLanguage(lang){
                if(lang === this.$i18n.locale){
                    return;
                }
                this.$i18n.locale = lang;
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
        },
        mounted() {
            const backToTopButton = document.getElementById('backToTop');
            this.backToTopButton = backToTopButton;
            this.scrollHandler = () => {
                const scrollDistance = window.scrollY || document.documentElement.scrollTop;
                if (scrollDistance > 300) {
                    backToTopButton.style.display = 'block';
                } else {
                    backToTopButton.style.display = 'none';
                }
            };

            // 绑定滚动事件
            window.addEventListener('scroll', this.scrollHandler);
        },
        beforeDestroy() {
            // 在组件销毁前移除滚动事件监听器
            if (this.scrollHandler) {
                window.removeEventListener('scroll', this.scrollHandler);
            }
        }

    }
</script>

<style scoped lang="scss">
    .home-page {
        position: relative;
        width: 100%;
        min-height: 100vh;
    }
    #header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        transition: all 0.3s ease;
        color: #fff;
        .el-menu-demo{
            display: flex; flex-wrap: wrap;border-right:none;background-color: transparent;
            &>li{
                flex: 1; text-align: center; font-size: rem(16);
                a{height: 100%; display: block;color:#fff;}
            }
        }
        &.header-fixed {
            position: sticky;background-color: #fff;color: #303133;
            .el-menu-demo{
                &>li{
                    a{color:#303133;}
                    &.is-active{
                        a{border-bottom: 1px solid #409eff; color: #409EFF;}
                    }
                }
            }
        }
    }
    .head-con{
        padding: 14px 50px;justify-content: space-between;align-content: center;
        .left_log{
            width: 320px; min-width: 320px; display: flex; align-items: center;
            &>img{width: 100%; max-width:200px;cursor: pointer;}
            &>p{margin:0 20px 0 10px; font-size: rem(18);cursor: pointer;}
        }
        .right_info{
            flex-grow: 1;justify-content: space-between;align-content: center;
            &>nav{flex-grow: 1;max-width: 1200px;}
        }
        .lang_nav{
            height: 30px; text-align: right; padding-right: 34px;
            &>span{padding: 0 6px; font-weight: normal; cursor:pointer;}
            span:nth-child(1)::after {
                content: '|';
                margin: 0 6px;
            }
            .active{font-weight: bold; }
        }
        .search{display:flex; position: relative; top:10px; width:210px ;min-width:210px; align-items: center; }
    }
    @include respond-to('md','max-width') {
        .head-con{
            padding: 14px 5px;
        }
        #header {
            .el-menu-demo{
                &>li{
                   font-size: rem(14);
                }
            }
        }
    }

    .home-layout{
        .head-con{
            .el-menu-demo{
                .el-menu-item{
                    &:focus, &:hover{
                        background-color: transparent;
                    }
                }
            }
        }
        .search{
            ::v-deep {
            .el-input__inner{background-color: transparent;}
            .el-input.is-active .el-input__inner, .el-input__inner:focus {
                border-color: #fff;
            }
        }
        }
    }

    .back-to-top {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 999;
        cursor: pointer;
        .back-to-top-btn {
            width: 60px;
            height: 60px;
            background: linear-gradient(145deg, #3385ff, #1890ff);
            border: none;
            box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 8px 16px rgba(24, 144, 255, 0.4);
                background: linear-gradient(145deg, #409EFF, #1890ff);
            }

            &:active {
                transform: translateY(0);
                box-shadow: 0 4px 8px rgba(24, 144, 255, 0.2);
            }

            .button-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 20px;
                    margin-bottom: 2px;
                }

                .button-text {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }

    // 淡入淡出动画
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }
</style>
