// store/modules/translations.js
import needData  from '@/data'

const getters = {
    localizedArr: (state, getters, rootState, rootGetters) => (i18n,theKey) => {
        return state?.[theKey].map(item => ({
            ...item,
            showText: i18n.t(item.i18nKey)
        }))
    }
}

export default {
    namespaced: true,  // 启用命名空间
    state: {
        activeIndex: '',
        menuItems: needData.menuItems,
        langs: needData.langs
    },

    mutations: {
        SET_ACTIVE_INDEX(state, index) {
            state.activeIndex = index
        },
        UPDATE_MENU_ITEMS(state, items) {
            state.menuItems = items
        }
    },

    actions: {
        updateActiveIndex({ commit, state }, route) {
            const currentMenuItem = state.menuItems.find(item => item.url === route.path)
            commit('SET_ACTIVE_INDEX', currentMenuItem ? currentMenuItem.index : '')
        },
        resetActiveIndex({ commit }) {
            commit('SET_ACTIVE_INDEX', '')
        }
    },
    getters
}






