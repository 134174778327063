export default {
    zh:'Chinese',
    en:'English',
    faculty:'Faculty',
    research_groups:'Research Groups',
    events:'Events',
    blog:'Blog',
    opportunities:'opportunities',
    about_contact:'About + Contact',
    internal:'Internal',
    desp:'Institute of Trustworthy Embodied AI',
    keyWord:'keyword',
    video_text:`TEAI: Pioneering Embodied Intelligence for a Smarter, Safer Tomorrow`,
    research: {
        title: 'RESEARCH PROJECTS',
        moreText: 'More Projects'
    },
    groupTitle: 'RESEARCH Groups',
    months: {
        DEC: 'DEC',
        // ... other months
    },
    new: 'new',
    newsMore:'More News',
    footer: {
        aboutUs: 'About us',
        qrCode: 'QR Code'
    },
    facultyPage: {
        title: 'Faculty',
        subTitle: '(INCLUDING AFFILIATED FACULTY)',
        more: 'Read More',
        nameLabel: 'Name:',
        titleLabel: 'Title:',
        searchLabel: 'Research Direction',
        homeLabel: 'Personal Homepage:',
        teachers: [
            {
              id: 1,
              name: 'Yu-Gang Jiang',
              title: 'Professor, PhD Supervisor',
              researchInterests: 'Computer Vision, Machine (Deep) Learning, Vision and Language, Trustworthy Artificial Intelligence',
              image: require('@/assets/img/Yu-GangJiang.jpg'),
              homepage: 'https://fvl.fudan.edu.cn/'
            },
            {
                id: 2,
                name: 'Xuanjing Huang',
                title: 'Professor, PhD Supervisor',
                researchInterests: 'Natural Language Processing, Information Retrieval, Large Language Models',
                image: require('@/assets/img/XuanjingHuang.jpg'),
                homepage: 'https://xuanjing-huang.github.io/chinese/'
       },
       {
                id: 3,
                name: 'QiZhang',
                title: 'Professor, PhD Supervisor',
                researchInterests: 'Natural Language Understanding, Large Language Models',
                image: require('@/assets/img/QiZhang.jpg'),
                homepage: 'http://qizhang.info/index_cn.html'
       },
      {
                id: 4,
                name: 'Xipeng Qiu',
                title: 'Professor, PhD Supervisor',
                researchInterests: 'Natural Language Processing, Deep Learning, Large Language Models',
                image: require('@/assets/img/XipengQiu.jpg'),
                homepage: 'https://xpqiu.github.io/'
       },
       {
                id: 5,
                name: 'Zuxuan Wu',
                title: 'Associate Professor, PhD Supervisor',
                researchInterests: 'Computer Vision, Deep Learning',
                image: require('@/assets/img/ZuxuanWu.jpg'),
                homepage: 'https://zxwu.azurewebsites.net/'
       },
       {
                id: 6,
                name: 'Yue Fei',
                title: 'Senior Supervisor',
                researchInterests: 'Artificial Intelligence, Computer Vision, Human-Computer Interaction',
                image: require('@/assets/img/YueFei.jpg'),
                homepage: 'https://yw.fudan.edu.cn/3e/49/c44571a671305/page.htm'
       },
       {
                id: 7,
                name: 'Yanwei Fu',
                title: 'Professor, PhD Supervisor',
                researchInterests: 'Transfer Learning based on Large Models, such as Few-Shot/Contextual Learning, Embodied Intelligence, Brain Signal Neural Decoding',
                image: require('@/assets/img/YanweiFu.jpg'),
                homepage: 'https://yanweifu.github.io/'
       },
       {
                id: 8,
                name: 'Yixin Cao',
                title: 'Pre-tenured Professor, PhD Supervisor',
                researchInterests: 'Natural Language Processing, Knowledge Engineering, Multimodal Integration',
                image: require('@/assets/img/YixinCao.jpg'),
                homepage: 'https://taominer.github.io/'
       },
       {
                id: 9,
                name: 'Xingjun Ma',
                title: 'Pre-tenured Professor, PhD Supervisor',
                researchInterests: 'Trustworthy Artificial Intelligence, Machine Learning',
                image: require('@/assets/img/XingjunMa.jpg'),
                homepage: 'http://xingjunma.com/'
       },
       {
                id: 10,
                name: 'Tao Gui',
                title: 'Associate Professor',
                researchInterests: 'Natural Language Processing, Large Language Models, Human-like Alignment, Agent Interaction',
                image: require('@/assets/img/TaoGui.jpg'),
                homepage: 'https://guitaowufeng.github.io/'
       },
       {
                id: 11,
                name: 'Zhineng Chen',
                title: 'Pre-tenured Professor, PhD Supervisor',
                researchInterests: 'Computer Vision, AI for Science (AI4S), AI-Generated Content (AIGC)',
                image: require('@/assets/img/ZhinengChen.jpg'),
                homepage: 'https://zhinchenfd.github.io/'
       }
          ]
    },
};