<!-- FacultyLabs.vue -->
<template>
    <div class="faculty-container">
        <div class="faculty-content">
            <h1 class="faculty-title">{{ title }}</h1>
            <div class="labs-grid">
                <div v-for="lab in labs" :key="lab.id" class="lab-card">
                    <div class="card-content">
                        <img :src="lab.image" :alt="lab.name" class="lab-image">
                        <h2 class="lab-name">{{ lab.name }}</h2>
                        <p class="lab-description">{{ lab.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FacultyLabs',
        props: {
            title: {
                type: String,
                default: 'INDIVIDUAL FACULTY LABS & GROUPS'
            },
            labs: {
                type: Array,
                required: true,
                default: () => []
            }
        }
    }
</script>

<style scoped lang="scss">
    .faculty-container {
        width: 100%;background-color: #f5f5f5;padding: 60px 10px;
    }

    .faculty-content {
        max-width: 1200px;
        margin: 0 auto;
    }

    .faculty-title {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
        color: #333;
    }

    .labs-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        padding: 0 20px;
    }

    .lab-card {
        background-color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;
        height: 100%;
    }

    .lab-card:hover {
        /* transform: translateY(-5px);*/
        background: rgba(108, 160, 220, 0.5);
        color: #ffffff;
    }
    .card-content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .lab-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 16px;
        object-fit: cover;
    }

    .lab-name {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
        text-align: center;
    }

    .lab-description {
        font-size: 14px;
        line-height: 1.5;
        text-align: center;
        margin: 0;
    }

    /* Responsive Design */
    @media screen and (max-width: 1200px) {
        .labs-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 900px) {
        .labs-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 600px) {
        .labs-grid {
            grid-template-columns: 1fr;
        }

        .faculty-title {
            font-size: 28px;
        }

        .lab-image {
            width: 80px;
            height: 80px;
        }
    }
</style>