<!-- components/FullScreenVideo.vue -->
<template>
    <div class="video-container">
        <video-player
                ref="videoPlayer"
                class="video-player"
                :options="playerOptions"
                :playsinline="true"
                @ready="onPlayerReady"
        />
        <!-- 自定义播放控制按钮 -->
        <div class="custom-controls">
            <button
                    class="control-btn"
                    @click="togglePlay"
                    :title="isPlaying ? '暂停' : '播放'"
            >
                <i :class="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"></i>
            </button>
        </div>

        <div class="overlay">
            <p><img src="@/assets/img/text.png" /></p></div>
    </div>
</template>

<script>
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player'

    export default {
        name: 'FullScreenVideo',
        components: {
            videoPlayer
        },
        props: {
            videoUrl: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                player: null,
                isPlaying: true,
                playerOptions: {
                    autoplay: true,
                    muted: true,
                    loop: true,
                    controls: false, // 隐藏默认控制栏
                    sources: [{
                        type: "video/mp4",
                        src: this.videoUrl
                    }],
                    fluid: true,
                    bigPlayButton: false,
                    aspectRatio: '16:9',
                }
            }
        },
        methods: {
            onPlayerReady(player) {
                this.player = player
                this.$emit('player-ready', player)
            },
            togglePlay() {
                if (!this.player) return

                if (this.player.paused()) {
                    this.player.play()
                    this.isPlaying = true
                } else {
                    this.player.pause()
                    this.isPlaying = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .video-container {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .overlay{
            position: absolute;display: flex;top: 0;left: 0;width: 100%;height: 100%; align-items: center; justify-content: center;
            background: rgba(0, 0, 0, 0.5); /* 半透明黑色蒙版 */ color: white;
            p{
               img{max-width: 90%; margin: 0 auto;}
            }
        }
        .video-player {
            width: 100%;
            height: 100%;

            ::v-deep .video-js {
                width: 100%;
                height: 100%;

                .vjs-tech {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .custom-controls {
            position: absolute;
            bottom: 10px;
            right: 20px;
            z-index: 2;
            .control-btn{width:48px;height:48px;border-radius:50%;color:#fff;
                font-size:36px;cursor:pointer;display:flex;align-items:center;justify-content:center;transition:all .3s ease;
                &:hover{transform:scale(1.1)}
                i{font-size:36px}
            }
        }
    }
</style>