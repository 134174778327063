<!-- views/ImageDemo.vue -->
<template>
    <div class="image-demo">
        <h2>图片优化示例</h2>
        <div class="image-grid">
            <div
                    v-for="(img, index) in images"
                    :key="index"
                    class="image-item"
            >
                <img
                        v-lazy="img.url"
                        :alt="img.alt"
                >
                <span class="image-number">图片 {{ index + 1 }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImageDemo',
        data() {
            return {
                images: Array.from({ length: 20 }, (_, i) => ({
                    // 使用 picsum 提供的示例图片
                    url: `https://picsum.photos/400/300?random=${i}`,
                    alt: `Random image ${i + 1}`
                }))
            }
        }
    }
</script>

<style scoped>
    .image-demo {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        padding: 20px 0;
    }

    .image-item {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .image-item img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        display: block;
        transition: transform 0.3s;
    }

    .image-item:hover img {
        transform: scale(1.05);
    }

    .image-number {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;
    }
</style>