<!-- Footer.vue -->
<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-main">
                <!-- Left section with logo and contact info -->
                <div class="contact-info">
                    <img :src="footerData.logo" :alt="footerData.companyName" class="logo">
                    <div class="info-items">
                        <div class="info-item">
                            <i class="el-icon-location"></i>
                            <span>{{ footerData.address }}</span>
                        </div>
                        <div class="info-item">
                            <i class="el-icon-phone"></i>
                            <span>{{ footerData.phone }}</span>
                        </div>
                        <div class="info-item">
                            <i class="el-icon-message"></i>
                            <span>{{ footerData.email }}</span>
                        </div>
                    </div>
                </div>

                <!-- Right section with QR code -->
                <div class="qr-section" v-if="$route.path==='/'">
                    <img :src="footerData.qrCode" :alt="$t('footer.qrCode')" class="qr-code">
                    <span class="qr-text"> <i class="el-icon-arrow-up"></i>{{ $t('footer.aboutUs') }}</span>
                </div>
            </div>

            <!-- Copyright section -->
            <div class="copyright">
               <span>{{ footerData.copyright }}</span>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'MyFooter',
        props: {
            footerData: {
                type: Object,
                required: true,
                default: () => ({
                    logo: '',
                    companyName: '',
                    address: '',
                    phone: '',
                    email: '',
                    qrCode: '',
                    copyright: ''
                })
            }
        },
        created() {
            // Verify all required data is present
            // const requiredFields = ['logo', 'companyName', 'address', 'phone', 'email', 'qrCode', 'copyright'];
            // requiredFields.forEach(field => {
            //     if (!this.footerData[field]) {
            //         console.warn(`Footer component: ${field} is missing`);
            //     }
            // });
        }
    }
</script>

<style scoped lang="scss">
    .footer {
        position: relative; z-index:2;
        background-color: #1a1a1a;
        color: #ffffff;
        padding: 20px 0;
        width: 100%;
    }

    .footer-content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .footer-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .contact-info {
        display: flex;
        align-items: center;
        gap: 80px;
        & > img {
            max-width: 300px;
        }
    }



    .info-items {
        display: flex;flex-direction: column;margin-top: 14px;gap: 10px;
    }

    .info-item {
        display: flex;
        align-items: center; font-size: 18px; margin-bottom: 14px;
        gap: 10px;
        color: #ffffff;
    }

    .info-item i {
        font-size: 22px;
    }

    .qr-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .qr-text {
        color: #ffffff;
        font-size: 18px;
        i{position:relative; top:1px;font-size: 22px; font-weight: bold; color:#0165B9; margin-right:5px;}
    }

    .copyright {
        text-align: center;
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
    }

    /* Responsive design */
    @media screen and (max-width: 768px) {
        .footer-main {
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }

        .contact-info {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .info-items {
            align-items: center;
        }
    }

    @media screen and (max-width: 480px) {
        .logo {
            width: 60px;
            height: 60px;
        }

        .qr-code {
            width: 80px;
            height: 80px;
        }

        .footer-content {
            padding: 0 15px;
        }
    }
</style>