<template>
    <div class="faculty">
        <div class="com-img">
            <img src="@/assets/img/fa1.jpg"/>
        </div>
        <faculty-section />
    </div>
</template>

<script>
    export default {
        name: 'Faculty',
        components: {
            FacultySection: () => import(/* webpackChunkName: "faculty-section" */ '@/components/FacultySection.vue'),
        },
        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>