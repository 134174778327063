// VideoPlayer.vue
<template>
    <div class="video-container">
        <video-player
                class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                @play="onPlayerPlay"
                @pause="onPlayerPause"
                @ended="onPlayerEnded"
                @timeupdate="onTimeUpdate"
        >
        </video-player>

        <!-- Custom play/pause button overlay -->
        <div
                class="play-button-overlay"
                @click="togglePlay"
                v-show="!isPlaying || showPlayButton"
                @mouseover="showPlayButton = true"
                @mouseleave="showPlayButton = false"
        >
            <el-button
                    type="text"
                    class="custom-play-button"
                    :icon="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"
            >
            </el-button>
        </div>

        <!-- Custom control bar -->
        <div class="custom-controls" @mouseover="showControls = true" @mouseleave="showControls = false">
            <!-- Progress bar -->
            <div class="progress-bar-container">
                <el-slider
                        v-model="currentProgress"
                        :max="100"
                        @change="onProgressChange"
                        class="progress-slider"
                ></el-slider>
            </div>

            <div class="controls-buttons">
                <!-- Play/Pause -->
                <el-button
                        type="text"
                        :icon="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"
                        @click="togglePlay"
                        class="control-button"
                ></el-button>

                <!-- Rewind 10s -->
                <el-button
                        type="text"
                        icon="el-icon-refresh-left"
                        @click="rewind"
                        class="control-button"
                ></el-button>

                <!-- Forward 10s -->
                <el-button
                        type="text"
                        icon="el-icon-refresh-right"
                        @click="forward"
                        class="control-button"
                ></el-button>

                <!-- Volume -->
                <div class="volume-control">
                    <el-button
                            type="text"
                            :icon="isMuted ? 'el-icon-turn-off' : 'el-icon-open'"
                            @click="toggleMute"
                            class="control-button"
                    ></el-button>
                    <el-slider
                            v-model="volume"
                            :max="100"
                            @change="onVolumeChange"
                            class="volume-slider"
                    ></el-slider>
                </div>

                <!-- Time display -->
                <span class="time-display">
          {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
        </span>

                <!-- Fullscreen -->
                <el-button
                        type="text"
                        icon="el-icon-full-screen"
                        @click="toggleFullscreen"
                        class="control-button"
                ></el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import 'video.js/dist/video-js.css'
    import 'vue-video-player/src/custom-theme.css'
    import { Slider } from 'element-ui';
    import { videoPlayer } from 'vue-video-player'

    export default {
        name: 'MyPlayer',
        components: {
            videoPlayer,
            'el-slider': Slider
        },
        props: {
            videoSource: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isPlaying: false,
                showPlayButton: false,
                showControls: false,
                isMuted: false,
                volume: 100,
                currentTime: 0,
                duration: 0,
                currentProgress: 0,
                playerOptions: {
                    autoplay: false,
                    controls: false,
                    muted: false,
                    language: 'zh-CN',
                    fluid: true,
                    bigPlayButton: false,
                    sources: [{
                        type: "video/mp4",
                        src: this.videoSource
                    }]
                }
            }
        },
        methods: {
            togglePlay() {
                if (this.isPlaying) {
                    this.$refs.videoPlayer.player.pause()
                } else {
                    this.$refs.videoPlayer.player.play()
                }
            },
            rewind() {
                const newTime = this.$refs.videoPlayer.player.currentTime() - 10
                this.$refs.videoPlayer.player.currentTime(Math.max(0, newTime))
            },
            forward() {
                const newTime = this.$refs.videoPlayer.player.currentTime() + 10
                this.$refs.videoPlayer.player.currentTime(Math.min(this.duration, newTime))
            },
            toggleMute() {
                const player = this.$refs.videoPlayer.player
                if (player.muted()) {
                    player.muted(false)
                    this.isMuted = false
                    this.volume = player.volume() * 100
                } else {
                    player.muted(true)
                    this.isMuted = true
                    this.volume = 0
                }
            },
            onVolumeChange(value) {
                const player = this.$refs.videoPlayer.player
                player.volume(value / 100)
                if (value === 0) {
                    player.muted(true)
                    this.isMuted = true
                } else if (this.isMuted) {
                    player.muted(false)
                    this.isMuted = false
                }
            },
            onProgressChange(value) {
                const time = (value / 100) * this.duration
                this.$refs.videoPlayer.player.currentTime(time)
            },
            onTimeUpdate() {
                const player = this.$refs.videoPlayer.player
                this.currentTime = player.currentTime()
                this.duration = player.duration()
                this.currentProgress = (this.currentTime / this.duration) * 100
            },
            toggleFullscreen() {
                const player = this.$refs.videoPlayer.player
                if (player.isFullscreen()) {
                    player.exitFullscreen()
                } else {
                    player.requestFullscreen()
                }
            },
            formatTime(seconds) {
                if (!seconds || isNaN(seconds)) return '00:00'
                const minutes = Math.floor(seconds / 60)
                seconds = Math.floor(seconds % 60)
                return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            },
            onPlayerPlay() {
                this.isPlaying = true
                setTimeout(() => {
                    if (this.isPlaying && !this.showPlayButton) {
                        this.showPlayButton = false
                    }
                }, 2000)
            },
            onPlayerPause() {
                this.isPlaying = false
                this.showPlayButton = true
            },
            onPlayerEnded() {
                this.isPlaying = false
                this.showPlayButton = true
            }
        }
    }
</script>

<style scoped>
    .video-container {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #000;
        overflow: hidden;
    }

    .video-player-box {
        width: 100%;
        height: 100%;
    }

    :deep(.video-js .vjs-big-play-button) {
        display: none !important;
    }

    .play-button-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .play-button-overlay:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .custom-play-button {
        font-size: 48px;
        color: #fff;
        transition: transform 0.2s ease;
    }

    .custom-play-button:hover {
        transform: scale(1.1);
    }

    .custom-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        padding: 10px;
        transition: opacity 0.3s ease;
        opacity: 0;
    }

    .video-container:hover .custom-controls,
    .custom-controls:hover {
        opacity: 1;
    }

    .progress-bar-container {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .controls-buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
    }

    .control-button {
        color: #fff;
        font-size: 20px;
        padding: 5px;
    }

    .volume-control {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .volume-slider {
        width: 80px;
    }

    .progress-slider {
        margin: 0;
    }

    :deep(.el-slider__runway) {
        margin: 8px 0;
    }

    :deep(.el-slider__bar) {
        background-color: #409EFF;
    }

    .time-display {
        color: #fff;
        font-size: 14px;
        margin: 0 10px;
    }

    /* 响应式样式 */
    @media (max-width: 768px) {
        .custom-play-button {
            font-size: 36px;
        }

        .control-button {
            font-size: 16px;
        }

        .volume-slider {
            width: 60px;
        }

        .time-display {
            font-size: 12px;
        }
    }

    @media (max-width: 480px) {
        .custom-play-button {
            font-size: 24px;
        }

        .volume-control {
            display: none;
        }

        .time-display {
            display: none;
        }
    }
</style>