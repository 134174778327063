<!-- BlogList.vue -->
<template>
    <div class="blog-list-container">
        <div class="blog-list-wrapper">
            <h1 class="h1-title blog_big_title">{{titleKey}}</h1>
            <div
                    v-for="(item, index) in blogList"
                    :key="index"
                    class="blog-item"
            >
                <!-- 左侧图标 -->
                <div class="item-icon">
                    <i class="el-icon-arrow-right"></i>
                </div>

                <!-- 主要内容区 -->
                <div class="item-content">
                    <!-- 标题区域 -->
                    <div class="title-section">
                        <h2 class="blog-title">{{ item.title }}</h2>
                    </div>

                    <!-- 作者区域 -->
                    <div class="authors-section">
                        <template v-if="item.authors && item.authors.length">
                          <span
                                  v-for="(author, authorIndex) in item.authors"
                                  :key="authorIndex"
                                  class="author-name">
                            {{ author }}
                            <span v-if="authorIndex < item.authors.length - 1">, </span>
                          </span>
                        </template>
                    </div>

                    <!-- 描述区域 -->
                    <div class="description-section">
                        <p class="description-text">{{ item.description }}</p>
                    </div>
                </div>

                <!-- 右侧图片区域 -->
                <div v-if="item.image" class="item-image">
                    <img v-lazy="item.image"/>
                </div>
            </div>

            <div class="blog__more">
                <el-button class="more-button">
                    Next
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BlogList',
        props: {
            titleKey: {
                type: String,
                default: ''
            },
            blogList: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data() {
            return {}
        },
        computed: {
            // 可以添加计算属性
        },
        methods: {
            // 可以添加方法
        }
    }
</script>

<style lang="scss" scoped>
    .blog-list-container {
        width: 100%;
        display: flex;
        justify-content: center;
        .blog-list-wrapper {
            width: 100%;
            max-width: 1200px;
            .blog_big_title{margin: 80px 0}

            .blog-item {
                display: flex;
                align-items: flex-start;
                padding: 24px 0 64px;
                margin-bottom: 40px;
                border-bottom: 1px solid #ebeef5;
                transition: all 0.3s ease;

                &:hover {
                    background-color: #f5f7fa;
                    transform: translateX(5px);
                }

                .item-icon {
                    margin-right: 16px;
                    color: #409EFF;
                    font-size: 20px;
                    padding-top: 4px;
                }

                .item-content {
                    flex: 1;
                    min-width: 0; // 防止子元素溢出
                    .title-section {
                        margin-bottom: 12px;

                        .blog-title {
                            margin: 0;
                            font-size: 30px;
                            font-weight: 600;
                            color: #303133;
                            line-height: 1.4;
                        }
                    }

                    .authors-section {
                        margin-bottom: 12px;

                        .author-name {
                            font-size: 18px;
                            color: #606266;
                            text-decoration: underline;
                            cursor: pointer;
                            font-weight: 500;
                            &:hover {
                                color: #409EFF;
                            }
                        }
                    }

                    .description-section {
                        .description-text {
                            margin: 0;
                            font-size: 16px;
                            color: #606266;
                            line-height: 1.6;
                        }
                    }
                }

                .item-image {
                    margin-left: 24px;
                    width: 270px;
                    flex-shrink: 0;

                    .el-image {
                        width: 100%;
                        max-width: 100%;
                        border-radius: 4px;
                        overflow: hidden;
                    }

                    .image-slot {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background: #f5f7fa;
                        color: #909399;
                        font-size: 30px;
                    }
                }
            }

            .blog__more{text-align: center; margin: 40px 0;}
            .more-button {
                padding: 4px 24px;
                font-size: 18px;  border-radius: 25px; border: 1px solid #1B1B1B;
                &:hover{color:#fff; background-color: #1B1B1B}
            }
        }
    }

    // 响应式设计
    @media screen and (max-width: 768px) {
        .blog-list-container {
            padding: 10px;

            .blog-list-wrapper {
                .blog-item {
                    padding: 16px;

                    .item-image {
                        width: 120px;
                        height: 80px;
                        margin-left: 16px;
                    }

                    .item-content {
                        .title-section {
                            .blog-title {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .blog-list-container {
            .blog-list-wrapper {
                .blog-item {
                    .item-image {
                        display: none;
                    }
                }
            }
        }
    }
</style>