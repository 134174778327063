<!-- InfoSection.vue -->
<template>
    <div class="info-section-container">
        <div class="info-section-wrapper">
            <div class="info-content">
                <!-- 左侧描述文本 -->
                <div class="description-column">
                    <p class="description-text">{{ description }}</p>
                </div>

                <!-- 右侧快速链接 -->
                <div class="quick-links-column">
                    <h2 class="quick-links-title">{{ linksTitle }}</h2>
                    <ul class="quick-links-list">
                        <li
                                v-for="(link, index) in links"
                                :key="index"
                                class="quick-link-item"
                        >
                            <span class="link-bullet">•</span>
                            <a
                                    :href="link.url"
                                    class="quick-link"
                                    :class="{ 'underlined': link.underlined }"
                            >
                                {{ link.text }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfoSection',
        props: {
            description: {
                type: String,
                required: true
            },
            linksTitle: {
                type: String,
                default: 'Quick Links'
            },
            links: {
                type: Array,
                required: true,
                validator: function(value) {
                    return value.every(item => {
                        return typeof item.text === 'string' &&
                            typeof item.url === 'string'
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-section-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;

        .info-section-wrapper {
            width: 100%;
            max-width: 1200px;

            .info-content {
                display: flex;
                gap: 40px;

                @media (max-width: 768px) {
                    flex-direction: column;
                    gap: 30px;
                }

                // 左侧描述文本列
                .description-column {
                    flex: 1;
                    min-width: 0;
                    .description-text {
                        margin: 0;
                        font-size: 22px;
                        line-height: 1.6;
                        color: #333;
                    }
                }

                // 右侧快速链接列
                .quick-links-column {
                    flex: 0 0 auto;
                    width: 40%;

                    @media (max-width: 768px) {
                        width: 100%;
                    }

                    .quick-links-title {
                        margin: 0 0 20px;
                        font-size: 26px;
                        font-weight: 600;
                        color: #333;
                    }

                    .quick-links-list {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        .quick-link-item {
                            display: flex;
                            align-items: center;
                            margin-bottom: 16px;
                            &:last-child {
                                margin-bottom: 0;
                            }

                            .link-bullet {
                                color: #1E88E5;
                                font-size: 48px;
                                margin-right: 8px;
                                line-height: 1;
                            }

                            .quick-link {
                                color: #333;
                                text-decoration: none;
                                font-size: 24px;
                                line-height: 1.4;
                                transition: all 0.3s ease;

                                &:hover {
                                    font-weight: 600;
                                    text-decoration: underline;
                                }

                                &.underlined {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>