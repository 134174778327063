var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-display-container"},[_c('div',{staticClass:"content-wrapper"},_vm._l((_vm.contentSections),function(section,index){return _c('div',{key:index,staticClass:"content-section"},[(section.title)?_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(section.title))]):_vm._e(),_c('div',{staticClass:"section-header",class:{
        'text-left': section.titleAlign === 'left',
        'text-right': section.titleAlign === 'right',
        'text-center': section.titleAlign === 'center'
      }},[(section.subtitle)?_c('h3',{staticClass:"section-subtitle"},[_vm._v(_vm._s(section.subtitle))]):_vm._e()]),_c('div',{staticClass:"content-block",class:{
        'image-left': section.imagePosition === 'left',
        'image-right': section.imagePosition === 'right'
      }},[(section.image)?_c('div',{staticClass:"image-container"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(section.image),expression:"section.image"}]})]):_vm._e(),_c('div',{staticClass:"text-content"},[_c('div',{staticClass:"text-inner"},[_c('p',{staticClass:"description"},[_vm._v(_vm._s(section.description))]),(section.link)?_c('div',{staticClass:"link-container"},[_c('a',{staticClass:"content-link",attrs:{"href":section.link.url}},[_vm._v(" → "+_vm._s(section.link.text)+" ")])]):_vm._e()])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }