<template>
    <div class="faculty-welcome">
        <section class="welcome-container">
            <el-row :gutter="20">
                <el-col :span="24" :md="12" class="welcome-text">
                    <h1 class="welcome-title">We Are Pleased to Welcome New Members of Our Faculty</h1>
                    <div class="faculty-intro">
                        <p>Diyi Yang who focuses on Computational Social Science and Natural Language Processing</p>
                        <p>Sanmi Koyejo who focuses on Trustworthy Machine Learning for Healthcare and Neuroscience</p>
                    </div>
                    <div class="action-buttons">
                        <el-button type="primary" class="action-button" @click="goToPage('/faculty')">
                            See the Entire Faculty
                            <i class="el-icon-arrow-right"></i>
                        </el-button>
                        <el-button type="primary" class="action-button" @click="goToPage('/opportunities')">
                            Join Us
                            <i class="el-icon-arrow-right"></i>
                        </el-button>
                    </div>
                </el-col>

                <el-col :span="24" :md="12">
                    <el-row :gutter="20" class="faculty-cards">
                        <el-col :span="12">
                            <div class="faculty-card">
                                <div class="avatar-container">
                                    <img src="@/assets/img/nv.png" alt="Faculty member" class="faculty-avatar">
                                </div>
                                <h3 class="faculty-name">Diyi Yang</h3>
                                <p class="faculty-field">Computational Social Science, Natural Language Processing</p>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="faculty-card">
                                <div class="avatar-container">
                                    <img src="@/assets/img/nan.png" alt="Faculty member" class="faculty-avatar">
                                </div>
                                <h3 class="faculty-name">Sanmi Koyejo</h3>
                                <p class="faculty-field">Trustworthy Machine Learning for Healthcare and Neuroscience</p>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

        </section>

    </div>
</template>

<script>
    export default {
        name: 'FacultyWelcome',
        data() {
            return {}
        },
        methods:{
            goToPage(path) {
                // 字符串路径
                this.$router.push(path);

                // 或者使用命名路由
                // this.$router.push({ name: 'About' });

                // 或者带有参数的路径
                // this.$router.push({ path: '/user', query: { userId: 123 } });
            }
        }
    }
</script>

<style scoped>
    .faculty-welcome {
        background: linear-gradient(180deg, #008DB9 0%, #004B87 100%);
        padding: 40px 20px;
        color: white;
    }

    .welcome-container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .welcome-title {
        font-size: 36px;
        margin-bottom: 24px;
        line-height: 1.2;
    }

    .faculty-intro {
        margin-bottom: 32px;
    }

    .faculty-intro p {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 1.5;
    }

    .action-buttons {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }

    .action-button {
        background-color: rgba(0, 0, 0, 0.2) !important;
        border: none !important;
        padding: 12px 24px !important;
        border-radius: 25px !important;
    }

    .action-button:hover {
        background-color: rgba(0, 0, 0, 0.3) !important;
    }

    .faculty-cards {
        margin-top: 32px;
    }

    .faculty-card {
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        height: 100%;
        /*box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);*/
    }

    .avatar-container {
        width: 228px;
        /*height: 120px;*/
        margin: 0 auto 16px;
        border-radius: 50%;
        overflow: hidden;
        background: #f5f5f5;
    }

    .faculty-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .faculty-name {
        color: #fff;
        margin-bottom: 8px;
        font-size: 22px;
    }

    .faculty-field {
        color: #fff;
        font-size: 18px;
        line-height: 1.4;
    }

    @media (max-width: 768px) {
        .welcome-title {
            font-size: 28px;
        }

        .faculty-intro {
            margin-bottom: 24px;
        }

        .faculty-cards {
            margin-top: 24px;
        }

        .avatar-container {
            width: 100px;
            height: 100px;
        }
    }
</style>