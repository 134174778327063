<!-- ContentDisplay.vue -->
<template>
    <div class="content-display-container">
        <div class="content-wrapper">
            <div
                    v-for="(section, index) in contentSections"
                    :key="index"
                    class="content-section"
            >
                <!-- 标题区域 -->
                <h2 v-if="section.title" class="section-title">{{ section.title }}</h2>
                <div
                        class="section-header"
                        :class="{
            'text-left': section.titleAlign === 'left',
            'text-right': section.titleAlign === 'right',
            'text-center': section.titleAlign === 'center'
          }"
                >

                    <h3 v-if="section.subtitle" class="section-subtitle">{{ section.subtitle }}</h3>
                </div>

                <!-- 图文内容区域 -->
                <div
                        class="content-block"
                        :class="{
            'image-left': section.imagePosition === 'left',
            'image-right': section.imagePosition === 'right'
          }"
                >
                    <!-- 图片部分 -->
                    <div v-if="section.image" class="image-container">
                        <img v-lazy="section.image" />
                    </div>

                    <!-- 文字内容部分 -->
                    <div class="text-content">
                        <div class="text-inner">
                            <p class="description">{{ section.description }}</p>
                            <div v-if="section.link" class="link-container">
                                <a
                                        :href="section.link.url"
                                        class="content-link"
                                >
                                    → {{ section.link.text }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentDisplay',
        props: {
            contentSections: {
                type: Array,
                required: true,
                default: () => [],
                validator: function(sections) {
                    return sections.every(section => {
                        return typeof section.title === 'string' ||
                            typeof section.description === 'string'
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-display-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;

        .content-wrapper {
            width: 100%;
            max-width: 1200px;

            .content-section {
                margin-bottom: 40px; padding-bottom: 40px; border-bottom: 1px solid #e0e0e0;
                &:last-child {
                    border-bottom:none;
                }
                .section-title {
                    margin: 0;
                    font-size: 40px;
                    font-weight: 600;
                    color: #1a1a1a;
                    line-height: 1.4;
                    padding: 8px;
                    background-color: #000;
                    color: #fff;
                }
                .section-header {
                    margin-bottom: 24px;

                    &.text-left {
                        text-align: left;
                    }

                    &.text-right {
                        text-align: right;
                    }

                    &.text-center {
                        text-align: center;
                    }



                    .section-subtitle {
                        margin: 8px 0 0;
                        font-size: 36px;
                        font-weight: 500;
                        color: #303133;
                    }
                }
                .content-block {
                    display: flex;
                    gap: 30px;
                    align-items: flex-start;

                    &.image-left {
                        flex-direction: row;
                    }

                    &.image-right {
                        flex-direction: row-reverse;
                    }

                    .image-container {
                        flex: 0 0 45%;
                        &>img{
                            width: 100%; max-width: 100%;
                        }
                    }

                    .text-content {
                        flex: 1;
                        min-width: 0;

                        .text-inner {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .description {
                                margin: 0 0 20px;
                                font-size: 22px;
                                line-height: 1.6;
                                color: #606266;
                            }

                            .link-container {
                                margin-top: auto; font-weight: bold;

                                .content-link {
                                    color: #000;
                                    text-decoration: none;
                                    font-weight: 500;
                                    font-size: 22px;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // 响应式设计
    @media screen and (max-width: 768px) {
        .content-display-container {
            padding: 15px;

            .content-wrapper {
                .content-section {
                    .section-title {
                        font-size: 38px;
                    }
                    .section-header {
                        .section-subtitle {
                            font-size: 32px;
                        }
                    }

                    .content-block {
                        flex-direction: column !important;
                        gap: 20px;

                        .image-container {
                            flex: 0 0 auto;
                            width: 100%;

                            .image-slot {
                                height: 200px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>